@import 'styles/base';

.login-form {
  &-and-word {
    width: 100%;
    margin: 16px 0;
    text-align: center;
  }
  &-logo svg {
    height: 56px;
  }
}

.login-form-logo {
  &:hover {
    cursor: pointer;
  }
}
